<template>
  <div id="inicio">
    <v-sheet
      :height="$vuetify.breakpoint.smAndUp ? '80vh' : '100vh'"
      color="primary"
      tile
    >
      <v-img
        :key="image"
        :src="image"
        gradient="to top, rgba(32, 32, 30, .85), rgba(32, 32, 30, .60)"
        height="100%"
      >
        <v-container fill-height white--text>
          <v-slide-x-transition appear>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <h1
                  :class="
                    $vuetify.breakpoint.smAndUp ? 'display-3' : 'display-2'
                  "
                  class="mb-2"
                  v-text="title"
                ></h1>
                <div class="title font-weight-light" v-text="subtitle" />
              </v-col>
            </v-row>
          </v-slide-x-transition>
        </v-container>
      </v-img>
    </v-sheet>
  </div>
</template>

<script>
export default {
  components: {
    // BuscarDominio:()=> import ('@/components/BuscarDominio')
  },
  data: () => ({
    titles: {
      inicio: {
        title: "BUSCA TU DOMINIO",
        // subtitle: "Registre su dominio .NI",
      },
      quienessomos: {
        title: "NETWORK INFORMATION CENTER",
        subtitle: "NIC.NI",
      },
      historia: {
        title: "HISTORIA",
        // subtitle: 'Phasellus gravida semper nisi. Fusce convallis metus id felis luctus adipiscing.'
      },
      misionvision: {
        title: "Misión - Visión",
        // subtitle: 'Phasellus gravida semper nisi. Fusce convallis metus id felis luctus adipiscing.'
      },
      valores: {
        title: "VALORES",
        // subtitle: 'Phasellus gravida semper nisi. Fusce convallis metus id felis luctus adipiscing.'
      },
      buscardominio: {
        title: "Buscar Dominio",
        subtitle: "Consultar Dominio",
      },
      informaciongeneral: {
        title: "INFORMACIÓN GENERAL",
        // subtitle: 'Phasellus gravida semper nisi. Fusce convallis metus id felis luctus adipiscing.'
      },
      cursocisco: {
        title: "Cursos Cisco",
        subtitle: "Ofrecemos capacitaciones gratuitas de CISCO.",
      },

      whois: {
        title: "WHOIS",
      },
      iniciarsesion: {
        title: "Iniciar Sesión",
      },
      crearusuario: {
        title: "Registrarse",
      },
      contactenos: {
        title: "CONTÁCTENOS",
      },
      faqs: {
        title: "FAQS",
      },
      compradominio: {
        title: "Registro de dominio en línea",
        subtitle:
          "Puedes registrar y administrar tus dominios desde nuestra web, fácil, rápido y seguro",
      },
      pasosdominio: {
        title: "PASOS PARA OBTENER UN DOMINIO",
      },
      requisitos: {
        title: "PASOS PARA REGISTRAR UN DOMINIO",
      },
    },

    // loading: false,
    // items:['.ni','.com','.edu','.net']
  }),

  computed: {
    namespace() {
      if (!this.$route || !this.$route.name) return "";

      return this.$route.name.toLowerCase();
    },
    image() {
      return "/static/" + this.namespace + "-team.jpg";
    },
    subtitle() {
      return this.titles[this.namespace].subtitle;
    },
    title() {
      return this.titles[this.namespace].title;
    },
  },
};
</script>
<style>
.v-application .display-3 {
  font-size: 48px !important;

  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  margin-top: 100px;
}
@media (max-width: 600px) {
  .v-application .display-2 {
    font-size: 40px !important;
    margin-top: 80px !important;
  }
}
@media (max-width: 460px) {
  .v-application .display-2 {
    margin-top: 80px !important;
  }
  .v-application .title {
    display: none;
  }
  .v-btn:not(.v-btn--round).v-size--large {
    height: 55px;
    min-width: 55px !important;
    padding: 0 19.5555555556px;
    margin-left: 5px;
    color: #fff;
  }
}
/* .theme--light.v-label {
    color: #fff !important;
} */
.v-btn:not(.v-btn--round).v-size--large {
  height: 55px;
  min-width: 78px;
  padding: 0 19.5555555556px;
  margin-left: 5px;
  color: #fff;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #000 !important;
}
.theme--light.v-select .v-select__selection--comma {
  color: #fff !important;
}
.mdi-menu-down::before {
  content: "\F035D";
  color: #fff;
}

.v-list-item__title {
  color: #000 !important;
}
</style>
